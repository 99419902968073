.treatment-bannerImage {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.treatment-page-title {
  margin-top: 1em;
  text-transform: capitalize;
}

.treatment-page-content {
  font-size: 1.125em;
  padding-top: 40px;
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  p {
    margin-block-start: 0;
  }
  .treatment-content {
    padding-left: 2em;
    padding-bottom: 2em;
    padding-right: 100px;
  }

  .treatment-section-label {
    font-weight: 700;
  }
}

@media only screen and (max-width: 860px) {
  .treatment-page-content {
    max-width: 500px;
    font-size: 1em;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    margin: 0 20px;
    .treatment-section-label {
      margin: 1em 0;
    }
    .treatment-content {
      padding: 0;
    }
  }
}