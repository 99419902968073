/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$bobby: "bobby", "times new roman", serif;
$source-sans: "Source Sans Pro", "system-ui",sans-serif;
$korolev: "korolev", sans-serif;
$montserrat: "montserrat", sans-serif;
$nexa: "nexa", sans-serif;


/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:           #FFF;
$grey-light:      #E6E7E8;
$grey-dark:       #4D4D4F;
$pop:             #2CCF94;
$secondary:       #007899;
$secondary-dark:  #005B8A;